<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box" v-if="path !== 'index' && path !== 'login'">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>eBooking</el-breadcrumb-item>
            <el-breadcrumb-item>概况</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="order-stat">
         <el-row><h6>概况</h6></el-row>
         <el-row class="statistical-data">
            <el-col :span="8">
               <el-row>
                  <el-col :span="6">今日订单数</el-col>
                  <el-col :span="12">{{ data.todayOrderCount }}</el-col>
               </el-row>
               <el-row>
                  <el-col :span="6">今日销售额 </el-col>
                  <el-col :span="12">{{ data.todaySumMoney }}</el-col>
               </el-row>
            </el-col>
            <el-col :span="8">
               <el-row>
                  <el-col :span="6">今日已售房间数</el-col>
                  <el-col :span="12">{{ data.todaySoldRoomsCount }}</el-col>
               </el-row>
               <el-row>
                  <el-col :span="6">今日待售房数</el-col>
                  <el-col :span="12">{{ todayUnSoldRoomsCount }}</el-col>
               </el-row>
            </el-col>
            <el-col :span="8">
               <el-row>
                  <el-col :span="8">待处理订单</el-col>
                  <el-col :span="12">{{ data.pendingOrdersCount }}</el-col>
               </el-row>
               <el-row>
                  <el-col :span="8">今日预抵房间数</el-col>
                  <el-col :span="12">{{ data.todayEtaRoomsCount }}</el-col>
               </el-row>
            </el-col>
         </el-row>
         <el-row class="bg">
            <el-row class="order-title">
               <h6>订单统计</h6>
            </el-row>
            <el-row class="order-date">
               <el-col class="m-right-10">
                  <el-button-group>
                     <el-button @click="handleOrderDate('LASTWEEK')" :class="dateType === 'LASTWEEK' ? 'bg-gradient' : ''">上周</el-button>
                     <el-button @click="handleOrderDate('WEEK')" :class="dateType === 'WEEK' ? 'bg-gradient' : ''">本周</el-button>
                     <el-button @click="handleOrderDate('MONTH')" :class="dateType === 'MONTH' ? 'bg-gradient' : ''">本月</el-button>
                     <el-button @click="handleOrderDate('CUSTOM')" :class="dateType === 'CUSTOM' ? 'bg-gradient' : ''">自定义</el-button>
                  </el-button-group>
               </el-col>
               <el-col>
                  <date-packer class="width-300" v-if="dateType === 'CUSTOM'" :datePacker="orderDateVal" @setDatePacker="getOrderDatePacker"></date-packer>
               </el-col>
            </el-row>
            <el-row class="order-line-chart">
               <el-row class="wrapper-line" id="wrapper-line"></el-row>
            </el-row>
         </el-row>
      </el-row>
   </div>
</template>

<script>
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
import { eBooking } from "@/api/interface/eBooking"
import { dateFactory } from '@/common/js/common'
export default {
   data() {
      return{
         data: {},   // 订单统计数据
         lineOptions: {
            xAxis: {
               type: 'category',
               data: []
            },
            yAxis: {
               type: 'value',
               minInterval: 1, // Y轴刻度间距至少为1
            },
            series: [{
               data: [],
               type: 'line',
               smooth: true,
               itemStyle: {
                  normal: {
                     color: '#1e80ff', //改变折线点的颜色
                     lineStyle: {
                        color: '#1e80ff' //改变折线颜色
                     }
                  }
               },
            }]
         },
         orderDateVal: [],     // 订单统计时间段
         beginTime: '',        // 订单统计开始时间
         endTime: '',          // 订单统计结束时间
         dateType: 'WEEK',     // 订单统计时间类型
         path: '',
      }
   },
   beforeRouteEnter(to, from, next) {
      next(vm => vm.path = from.name)
   },
   computed: {
      ...mapState(['hotelInfo']),
      todayUnSoldRoomsCount() {
         let count = this.data.todayUnSoldRoomsCount
         return count = count < 0 ? 0 : count
      }
   },
   mounted() {
      const { accountType } = JSON.parse(sessionStorage.getItem('userInfo'))
      if (accountType === 'HOTEL') {
         this.getHotels()
      }else {
         this.getStatistics()
         this.getOrderStatistics()
      }
      this.accountType = JSON.parse(sessionStorage.getItem('userInfo')).accountType
      let wrapperline = this.$echarts.init(document.getElementById('wrapper-line'))
      window.onresize=function(){
         wrapperline.resize()
      }
   },
   methods: {
      // 获取酒店ID
      getHotels(){
         const url = urlObj.getHotelPage + `?limit=1&page=1`
         const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
         const param = { hotelId: userInfo.extra && userInfo.extra.hotelId || '', companyId: userInfo.storeId || '' }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               let hotelList = res.records
               this.$store.dispatch('setHotelInfo', hotelList[0])
               sessionStorage.setItem('hotelInfo', JSON.stringify(hotelList[0]))
               this.hotelId = hotelList[0].id
               this.getOrderStatistics()
               this.getStatistics()
            }
         })
      },
      // 获取数据统计
      getStatistics(){
         const url = eBooking.statistics
         const param = { hotelId: this.hotelInfo.id }
         this.$axios.get(url, param).then(res => res.success && (this.data = res.records))
      },
      // 获取订单统计
      getOrderStatistics(){
         const url = eBooking.statisticsByDate
         let param = { orderType: 'E_BOOKING', hotelId: this.hotelInfo.id }
         if (this.dateType === 'LASTWEEK') {
            param.beginTime = dateFactory.getWeekDate('last', 'start', false)
            param.endTime = dateFactory.getWeekDate('the', 'start', false)
         } else if (this.dateType === 'CUSTOM') {
            param.beginTime = this.beginTime.split(" ")[0]
            param.endTime = this.endTime.split(" ")[0]
         } else {
            param.dateType = this.dateType
         }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               let data = res.records
               let arr = []
               this.lineOptions.series[0].data.length = 0
               this.lineOptions.xAxis.data = 0
               data.forEach(item => {
                  arr.push(item.date)
                  this.lineOptions.series[0].data.push(Number(item.orderCount))
               })
               if (this.dateType === 'MONTH' || this.dateType === 'CUSTOM') {
                  this.lineOptions.xAxis.data = arr.map(item => {
                     return item.split('-')[2]
                  }).sort()
               }else {
                  this.lineOptions.xAxis.data = arr
               }
               this.$echarts.init(document.getElementById('wrapper-line')).setOption(this.lineOptions)

            }
         })
      },
      // 按订单统计时间段过滤
      getOrderDatePacker(dateVal){
         if (dateVal) {
            this.beginTime = dateVal[0]
            this.endTime = dateVal[1]
         } else {
            this.beginTime = ''
            this.endTime = ''
         }
         this.getOrderStatistics()
      },
      // 按订单统计时间类型过滤
      handleOrderDate(date){
         this.dateType = date
         if (this.dateType === 'CUSTOM') return
         this.getOrderStatistics()
      },
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         if (newVal.id !== oldVal.id && oldVal) this.getStatistics()
      }
   }
}
</script>

<style scoped lang="scss">
.order-stat{
   padding: 0 5px; background:#FFF; border-radius: 8px;
   h6{ line-height: 40px; font-size: 18px; padding: 10px 30px }
   .statistical-data{
      line-height: 50px; text-indent: 10px; padding: 10px 20px 30px;
      h6{ font-size: 14px }
      > .el-col{ padding: 0 10px; position: relative }
      > .el-col:before{
         content: ''; position: absolute; width: 1px;
         height: 100px; top: 20px; right: 10%; background-color: #eeeeee;
      }
   }
   .order-title{
      border-bottom: solid 1px #eeeeee;
   }
   .order-date{
      display: flex; justify-content: flex-end; line-height: 30px; padding: 10px 30px;
      .order-type{
         display:flex; align-items: center; flex:1;
         > div{
            width:60px; height:2px; background:#4a90e2; margin:0 0 0 60px;
            > p { height:8px; width:8px; border-radius:50%; background:#4a90e2; margin:-3px auto 0; }
         }
      }
      div{ width: auto }
      div:nth-child(1){ font-size: 0.9rem }
      span{ margin: 0 14px 0 0 }
   }
   .order-line-chart{
      padding: 10px 30px;
      .wrapper-line{ height: 500px }
   }
}
</style>
